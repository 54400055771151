<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <user v-bind:id="userId" v-bind:my-account="myAccount" v-bind:save-handler="saveHandler"></user>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import User from "@/components/app/user/User";
    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: 'MyAccount_view',
        data: function(){
            return {
                pageName: 'MyAccount',
                segments: [
                    { link: true, text: 'Users', routeName: 'App.Users' },
                    { link: false, text: 'User' },
                ],
                userId: 0,
                myAccount: true,
            }
        },
        components: {
            User,
            BreadCrumb
        },
        props: {
        },
        computed: {

        },
        methods:{
            saveHandler: async function () {
                //await this.$router.push({name: 'App.Users'});
                await userService.userInit();
            },
        },
        created() {
            let user = this.$store.getters['auth/user'];
            this.userId = user.id;
            console.log('my acc created: ', user.id);
        }
    }
</script>

<style scoped>

</style>
